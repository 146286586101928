<template>
  <!-- Authors Table Card -->
  <a-card
    :bordered="false"
    class="header-solid h-full"
    :bodyStyle="{ padding: 0 }"
  >
    <template #title>
      <a-row type="flex" align="middle">
        <a-col :span="24" :md="12">
          <h5 class="font-semibold m-0">Data Naskah Market</h5>
        </a-col>
      </a-row>
    </template>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Invoice</th>
            <th class="text-left">Customer</th>
            <th class="text-left">Naskah</th>
            <th class="text-left">Harga</th>
            <th class="text-left">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in data" :key="item.id">
            <td>
              <div class="table-avatar-info">
                <div class="avatar-info">
                  <h6>{{ item.invoice }}</h6>
                </div>
              </div>
            </td>

            <td>
              <div class="table-avatar-info">
                <div class="avatar-info">
                  <h6>{{ item.customer.name }}</h6>
                  <p>{{ item.customer.email }}</p>
                </div>
              </div>
            </td>

            <td>
              <div class="table-avatar-info">
                <div class="avatar-info">
                  <h6>{{ item.market.naskah.title }}</h6>
                  <p>
                    {{ item.market.naskah.user.type }}
                    {{ item.market.naskah.user.name }}
                  </p>
                </div>
              </div>
            </td>

            <td>
              <div class="table-avatar-info">
                <div class="avatar-info">
                  <h6>{{ item.market.price }}</h6>
                </div>
              </div>
            </td>

            <td>
              <a-badge status="processing" text="Baru" v-if="item.status == 0"/>
              <a-badge status="success" text="Berhasil"  v-if="item.status == 2"/>
              <a-badge status="error" text="Gagal"  v-if="item.status == 3"/>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </a-card>
  <!-- / Authors Table Card -->
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // Active button for the "Authors" table's card header radio button group.
      dataType: "all",
    };
  },
  methods: {
    filterMarket() {
      this.$emit("filter-data", this.dataType);
    },
  },
};
</script>
